<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient
            :memberId="memberId"
            :selectValue="selectValue"
            @change="userChange"
          />
          <TemplateTreeList
            :type="'check'"
            :list-data="listData1"
            :selectValue="listSelect2"
            @change="listChange"
          ></TemplateTreeList>
          <div class="box-div">
            <div class="title">
              临床特征
              <div
                style="display: flex;    align-items: baseline;"
                @click="userOpen1()"
              >
                <div v-if="selectValue1 !== ''">
                  {{ selectValue1 }}
                </div>
                <div style="color: #A6A6A6" v-else>
                  请选择
                </div>
                <!--        <div>-->
                <van-icon
                  name="arrow"
                  style="margin-left: 5px"
                  color="#A7A7A7"
                  size="17px"
                />
                <!--        </div>-->
              </div>
            </div>
          </div>
          <div class="box-div">
            <div class="title">
              症状持续时间
              <div
                style="display: flex;    align-items: baseline;"
                @click="userOpen2()"
              >
                <div v-if="selectValue2 !== ''">
                  {{ selectValue2 }}
                </div>
                <div style="color: #A6A6A6" v-else>
                  请选择
                </div>
                <!--        <div>-->
                <van-icon
                  name="arrow"
                  style="margin-left: 5px"
                  color="#A7A7A7"
                  size="17px"
                />
                <!--        </div>-->
              </div>
            </div>
          </div>
          <TemplateTreeList
            :type="'check'"
            :list-data="listData2"
            :selectValue="listSelect2"
            @change="listChange"
          ></TemplateTreeList>
          <div class="box-div">
            <div class="title">
              <span style="width:40%">影像检查</span>
              <div
                style="display: flex;    align-items: baseline;"
                @click="userOpen3()"
              >
                <div v-if="selectValue4.length !== 0">
                  <span v-for="(item, index) in selectValue4" :key="index"
                    >{{ item }}<br
                  /></span>
                </div>
                <div style="color: #A6A6A6" v-else>
                  请选择
                </div>
                <!--        <div>-->
                <van-icon
                  name="arrow"
                  style="margin-left: 5px"
                  color="#A7A7A7"
                  size="17px"
                />
                <!--        </div>-->
              </div>
            </div>
          </div>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button
              class="formula-template-button"
              block
              type="info"
              @click="submit"
              style="width:90px"
              >提交</van-button
            >
          </div>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText1 || countText1 !== ''">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span>{{countText1}}</div>
            </div><div>
              <div class="CalculationDetail"><span></span>{{countText2}}</div>
            </div><div>
              <div class="CalculationDetail"><span></span>{{countText3}}</div>
            </div><div>
              <div class="CalculationDetail"><span></span>{{countText4}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults
        :countText="countText"
        :countTextList="countTextList"
        v-if="show"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker1" position="bottom">
      <van-picker
        title="临床表现"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm1"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker
        title="症状持续时间"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm2"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup v-model="showPicker3" position="bottom">
      <!-- <van-picker
          title="影像检查"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm3"
          @cancel="onCancel"
      /> -->
      <div class="checkbox">
        <div class="checkbox_top">
          <span style="color: #969799;font-size: 14px" @click="onCancel"
            >取消</span
          >
          <span
            style="font-weight: 500;font-size: 16px; line-height: 20px; text-align: center"
            >影像检查</span
          >
          <span style="color: #576b95;font-size: 14px" @click="onConfirm3"
            >确认</span
          >
        </div>
        <van-checkbox-group
          v-model="val"
          @change="changeCheck"
          ref="checkboxGroup"
        >
          <van-cell-group>
            <van-cell
              v-for="(item, index) in columns"
              clickable
              :key="item"
              :title="item"
              @click="toggle(index, item)"
              style="font-size:16px;padding:10px 20px;color:black"
            >
              <template #right-icon>
                <van-checkbox :name="item" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </van-popup>
    <!-- <van-popup v-model="showPicker3" position="bottom">
      <van-checkbox-group ref="checkboxGroup">
        <van-cell-group>
          <van-cell v-for="(item, index) in columns" :key="index">
            <template #right-icon>
              <van-checkbox :name="item" ref="checkboxes" shape="square" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-popup> -->
  </div>
</template>

<script>
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateTreeList from "@/components/Template3/TemplateTreeList";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data() {
    return {
      compute:true,
      show: false,
      countTextList: [],
      listSelect: [],
      listSelect1: [],
      listSelect2: [],
      listData1: [
        {
          title: "年龄≥60岁",
        },
        {
          title: "高血压",
          tips: "SBP≥140mmHg或dbp≥90mmHg",
        },
      ],
      listData2: [
        {
          title: "糖尿病史",
          // content: '例如(包括，但不限于):近期(<3个月)MI、CVA、TIA或 CAD/支架病史。进行性心肌缺血或严重瓣膜功能障碍、严重的射血分数降低、败血症、DIC、ARD、ESRD未定期透析。'
        },
        {
          title: "双重TIA(7d)",
          // content: '例如(包括，但不限于):近期(<3个月)MI、CVA、TIA或 CAD/支架病史。进行性心肌缺血或严重瓣膜功能障碍、严重的射血分数降低、败血症、DIC、ARD、ESRD未定期透析。'
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      selectValue1: "",
      selectValue2: "",
      selectValue3: "",
      selectValue4: [],
      username: "",
      value: "",
      showPopover: false,
      showPicker1: false,
      showPicker2: false,
      showPicker3: false,
      columns: [],
      columns1: ["单侧无力", "不伴无力的言语障碍"],
      columns2: ["≥60min", "10-59min"],
      columns3: ["同侧颈动脉狭窄≥50%", "DWI检查出现高信号"],
      nianling: "",
      xingbie: null,
      tableData: {},
      countText: "",
      countText1: "",
      countText2: "",
      countText3: "",
      countText4: "",
      DisplayResults1:'',
      DisplayResults2:'',
      DisplayResults3:'',
      DisplayResults4:'',
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
      result1: 0,
      result2: 0,
      result3: 0,
      result4: 0,
      verdict1: "",
      verdict2: "",
      verdict3: "",
      verdict4: "",
      val: [],
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateTreeList,
    CalculationResults,
  },
  async created() {
    await this.test();
    await this.getTableData();
  },

  methods: {
    changeCheck() {
      console.log(this.val);
      // this.val= val;
    },
    toggle(index, item) {
      console.log("toggle:", index, item);
    },
    listChange(result) {
      this.show = false;
      this.listSelect = result;
      console.log(result);
    },
    coumputTetxt() {
      this.show = true;
      this.listSelect = this.listSelect.filter(
        (item1) => !this.columns1.some((item2) => item1 === item2)
      );
      this.listSelect = this.listSelect.filter(
        (item1) => !this.columns2.some((item2) => item1 === item2)
      );
      this.listSelect = this.listSelect.filter(
        (item1) => !this.columns3.some((item2) => item1 === item2)
      );
      if (this.selectValue1 !== "") {
        this.listSelect.push(this.selectValue1);
      }
      if (this.selectValue2 !== "") {
        this.listSelect.push(this.selectValue2);
      }
      if (this.selectValue4.length !== 0) {
        this.listSelect.push(...this.selectValue4);
      }
      // eslint-disable-next-line no-unused-vars
      let i1 = 0;
      let i2 = 0;
      let i3 = 0;
      let i4 = 0;
      if (this.listSelect.includes("年龄≥60岁")) {
        i1++;
        i2++;
        i3++;
        i4++;
      }
      if (this.listSelect.includes("高血压")) {
        i1++;
        i2++;
        i3++;
        i4++;
      }
      if (this.listSelect.includes("单侧无力")) {
        i1 = i1 + 2;
        i2 = i2 + 2;
        i3 = i3 + 2;
        i4 = i4 + 2;
      }
      if (this.listSelect.includes("不伴无力的言语障碍")) {
        i1++;
        i2++;
        i3++;
        i4++;
      }
      if (this.listSelect.includes("≥60min")) {
        i1 = i1 + 2;
        i2 = i2 + 2;
        i3 = i3 + 2;
        i4 = i4 + 2;
      }
      if (this.listSelect.includes("10-59min")) {
        i1++;
        i2++;
        i3++;
        i4++;
      }
      if (this.listSelect.includes("糖尿病史")) {
        i2++;
        i3++;
        i4++;
      }
      if (this.listSelect.includes("双重TIA(7d)")) {
        i3 = i3 + 2;
        i4 = i4 + 2;
      }
      if (this.listSelect.includes("同侧颈动脉狭窄≥50%")) {
        i4 = i4 + 2;
      }
      if (this.listSelect.includes("DWI检查出现高信号")) {
        i4 = i4 + 2;
      }
      console.log(i4);
      if (i1 <= 2) {
        this.verdict1 = "低危";
      } else if (i1 <= 4) {
        this.verdict1 = "中危";
      } else if (i1 <= 6) {
        this.verdict1 = "高危";
      }
      if (i2 <= 3) {
        this.verdict2 = "低危";
      } else if (i2 <= 5) {
        this.verdict2 = "中危";
      } else if (i2 <= 7) {
        this.verdict2 = "高危";
      }
      if (i3 <= 3) {
        this.verdict3 = "低危";
      } else if (i3 <= 5) {
        this.verdict3 = "中危";
      } else if (i3 <= 9) {
        this.verdict3 = "高危";
      }
      if (i4 <= 3) {
        this.verdict4 = "低危";
      } else if (i4 <= 7) {
        this.verdict4 = "中危";
      } else if (i4 <= 13) {
        this.verdict4 = "高危";
      }
      this.countText1 ="ABCD 分值：\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + i1 +"分" +"\xa0\xa0" +this.verdict1;
      this.countText2 ="ABCD2 分值：\xa0\xa0\xa0\xa0\xa0" +i2 +"分" +"\xa0\xa0" +this.verdict2;
      this.countText3 = "ABCD3 分值：\xa0\xa0\xa0\xa0\xa0" +i3 +"分" +"\xa0\xa0" + this.verdict3;
      this.countText4 ="ABCD3-1 分值：\xa0\xa0" + i4 + "分" + "\xa0\xa0" + this.verdict4;

      this.DisplayResults1 = "ABCD 分值：" + i1 +"分" +this.verdict1;
      this.DisplayResults2 ="ABCD2 分值：" +i2 +"分" +this.verdict2;
      this.DisplayResults3 = "ABCD3 分值：" +i3 +"分"  + this.verdict3;
      this.DisplayResults4 ="ABCD3-1 分值：" + i4 + "分" + this.verdict4;


      this.countTextList = [];
      this.countTextList.push(
        this.countText1,
        this.countText2,
        this.countText3,
        this.countText4
      );

      this.result1 = i1;
      this.result2 = i2;
      this.result3 = i3;
      this.result4 = i4;
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "HEJOGHXK",
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);
        if (res.code === 0) {
          this.listSelect1 = res.data.content.value;
          this.listSelect2 = res.data.content.value;
          this.listSelect3 = res.data.content.value;
          this.listSelect = res.data.content.value;
          for (var item of this.columns1) {
            for (var da of res.data.content.value) {
              if (item === da) {
                this.selectValue1 = item;
                break;
              }
            }
          }
          for (var item1 of this.columns2) {
            for (var da1 of res.data.content.value) {
              if (item1 === da1) {
                this.selectValue2 = item1;
                break;
              }
            }
          }
          for (var item2 of this.columns3) {
            for (var da2 of res.data.content.value) {
              if (item2 === da2) {
                this.selectValue4.push(item2);
                break;
              }
            }
          }
          if (res.data.patientId)
            this.selectValue = {
              id: res.data.patientId,
              name: res.data.patientName,
            };
          this.coumputTetxt();
        }
      }
    },
    async submit() {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }

      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          value: this.listSelect,
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {
          ABCD: { value: this.result1, result: this.verdict1 },
          ABCD2: { value: this.result2, result: this.verdict2 },
          ABCD3: { value: this.result3, result: this.verdict3 },
          ABCD31: { value: this.result4, result: this.verdict4 },
          DisplayResults:this.DisplayResults1 + "；" + this.DisplayResults2 + "；" + this.DisplayResults3 + "；" + this.DisplayResults4
        },
      };
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return;
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm1(value) {
      //   this.listSelect = this.listSelect.filter((item1)=>!this.columns1.some((item2)=>item1 === item2))
      // this.listSelect.push(value)
      this.selectValue1 = value;
      this.showPicker1 = false;
    },
    onConfirm2(value) {
      //   this.listSelect = this.listSelect.filter((item1)=>!this.columns2.some((item2)=>item1 === item2))
      // this.listSelect.push(value)
      this.selectValue2 = value;
      this.showPicker2 = false;
    },
    onConfirm3() {
      //   this.listSelect = this.listSelect.filter((item1)=>!this.columns3.some((item2)=>item1 === item2))
      // this.listSelect.push(value)
      // this.selectValue3 = value;
      this.selectValue4 = this.val;
      this.showPicker3 = false;
    },
    onCancel() {
      this.showPicker1 = false;
      this.showPicker2 = false;
      this.showPicker3 = false;
    },
    async onSubmit() {
      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          shengao: { value: this.shengao, unit: "cm" },
          tizhong: { value: this.tizhong, unit: "kg" },
          nianling: { value: this.nianling, unit: "岁" },
          xingbie: { value: this.xingbie == 1 ? "男" : "女" },
          // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
        },
        result: {},
      };
      if (this.xingbie == 1) {
        data.result.nanxing = { value: this.result, unit: "kcal" };
      } else {
        data.result.nvxing = { value: this.result, unit: "kcal" };
      }
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
      } else {
        Toast.fail(res.message);
      }
    },
    userChange(data) {
      if (data.birthday && data.birthday !== "") {
        this.nianling = this.calculateAge(data.birthday);
      } else {
        this.nianling = "";
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
    userOpen1() {
      (this.columns = this.columns1), (this.showPicker1 = true);
      this.show = false;
    },
    userOpen2() {
      (this.columns = this.columns2), (this.showPicker2 = true);
      this.show = false;
    },
    userOpen3() {
      (this.columns = this.columns3), (this.showPicker3 = true);
      this.show = false;
      this.val=[...this.selectValue4]
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  height: 100%;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.title {
  display: flex;
  justify-content: space-between;
}
.checkbox {
  height: 300px;
  width: 100%;
  // padding: 20px 10px;
}
.checkbox_top {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
}
.van-checkbox-group {
  width: 100%;
  margin: 30px auto;
  border: none;
}
.van-checkbox-group {
  .van-cell:after {
    border: none;
  }
}
::v-deep .van-hairline--top-bottom::after {
  border: none;
}

::v-deep .van-cell__title {
  //  margin-left: 20px;
  font-size: 14px;
  //  margin-top:10px
}
::v-deep .van-icon {
  //  margin-right: 20px;
}
::v-deep .van-icon-success {
  // margin-right: 20px;
}
</style>
